<template>
  <div class="create-building">
    <LobbyTvAppbar />
    <div class="ma-4">
      <LobbyTvForm />
    </div>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Create Building
 * ==================================================================================
 **/

import { mapMutations } from 'vuex'
import LobbyTvAppbar from '@/views/Home/Settings/LobbyTv/components/LobbyTvAppbar'
import LobbyTvForm from '@/views/Home/Settings/LobbyTv/components/LobbyTvForm'

export default {
  components: {
    LobbyTvAppbar,
    LobbyTvForm,
  },

  created() {
    this.clearDetails()
  },

  methods: {
    ...mapMutations({
      clearDetails: 'building/clearBuildingDetails',
    }),
  },
}
</script>
