<template>
  <div class="lobby-tv-form">
    <v-form ref="form" class="building-form__form" @submit.prevent="submit">
      <v-row>
        <v-col cols="12" md="6">
          <h3 class="pb-5 primary--text">{{ header }}</h3>

          <div class="mb-4 d-flex flex-column" v-if="isUpdate">
            <label class="text-field-label">TV Code</label>
            <b>{{ lobbyTv.tv_code }}</b>
          </div>

          <span class="red--text"><strong>* </strong></span>
          <label class="text-field-label">Building</label>
          <SelectBuilding
            flat
            solo
            required
            class="mb-2"
            v-model="form.building_id"
            :pre-select="!isUpdate"
            :error-messages="form.$getError('building_id')"
            :loading="form.$busy"
            :disabled="form.$busy"
          />

          <span class="red--text"><strong>* </strong></span>
          <label class="text-field-label">TV Name</label>
          <v-text-field
            flat
            solo
            required
            class="mb-2"
            placeholder="TV Name"
            v-model="form.tv_name"
            :error-messages="form.$getError('tv_name')"
            :loading="form.$busy"
            :disabled="form.$busy"
          />

          <div class="d-flex color-preview-wrapper">
            <span class="red--text"><strong>* </strong></span>
            <label class="text-field-label">Primary Color</label>
            <div
              class="preview-box"
              :style="{ backgroundColor: form.primary_color }"
            ></div>
          </div>
          <v-text-field
            flat
            solo
            required
            class="mb-2"
            placeholder="Primary color"
            prefix="#"
            v-model="form.primary_color"
            :error-messages="form.$getError('primary_color')"
            :loading="form.$busy"
            :disabled="form.$busy"
          />

          <div class="d-flex color-preview-wrapper">
            <span class="red--text"><strong>* </strong></span>
            <label class="text-field-label">Secondary Color</label>
            <div
              class="preview-box"
              :style="{ backgroundColor: form.secondary_color }"
            ></div>
          </div>
          <v-text-field
            flat
            solo
            required
            class="mb-2"
            placeholder="Secondary color"
            prefix="#"
            v-model="form.secondary_color"
            :error-messages="form.$getError('secondary_color')"
            :loading="form.$busy"
            :disabled="form.$busy"
          />

          <span class="red--text"><strong>* </strong></span>
          <label class="text-field-label">Location Name</label>
          <v-text-field
            ref="location_name"
            v-model="form.location_name"
            flat
            solo
            class="mb-2"
            :error-messages="form.$getError('location_name')"
            :loading="form.$busy"
            :disabled="form.$busy"
          />

          <div class="d-flex flex-row" style="gap: 2rem">
            <div>
              <span class="red--text"><strong>* </strong></span>
              <label class="text-field-label">Lat</label>
              <v-text-field
                ref="latitude"
                v-model="form.latitude"
                flat
                solo
                class="mb-2"
                :error-messages="form.$getError('latitude')"
                :loading="form.$busy"
                :disabled="form.$busy"
              />
            </div>
            <div>
              <span class="red--text"><strong>* </strong></span>
              <label class="text-field-label">Long</label>
              <v-text-field
                ref="longitude"
                v-model="form.longitude"
                flat
                solo
                class="mb-2"
                :error-messages="form.$getError('longitude')"
                :loading="form.$busy"
                :disabled="form.$busy"
              />
            </div>
          </div>

          <span class="red--text"><strong>* </strong></span>
          <label class="text-field-label">Phone Number</label>
          <v-text-field
            flat
            solo
            required
            class="mb-2"
            placeholder="Phone Number"
            v-model="form.phone_number"
            :error-messages="form.$getError('phone_number')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>

          <v-checkbox
            flat
            solo
            label="Set is active"
            class="mt-0"
            :error-messages="form.$getError('is_active')"
            v-model="form.is_active"
            :loading="form.$busy"
            :disabled="form.$busy"
          />
        </v-col>

        <v-col cols="12" md="6">
          <span class="red--text"><strong>* </strong></span>
          <label class="text-field-label">Logo</label>
          <LobbyTvLogoUpload
            class="mt-2 mb-4"
            :loading="form.$busy"
            :error="form.$getError('logo')"
            :for-update="isUpdate"
            @updated="form.$clearError('logo')"
          />

          <label class="text-field-label">Background Image</label>
          <LobbyTvBackgroundImageUpload
            class="mt-2 mb-4"
            :loading="form.$busy"
            :error="form.$getError('background_image')"
            :for-update="isUpdate"
            @updated="form.$clearError('background_image')"
          />

          <label class="text-field-label">Placeholder Gallery Image</label>
          <LobbyTvPlaceholderGalleryImageUpload
            class="mt-2 mb-4"
            :loading="form.$busy"
            :error="form.$getError('placeholder_gallery_image')"
            :for-update="isUpdate"
            @updated="form.$clearError('placeholder_gallery_image')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <div class="mt-4">
            <v-btn
              type="submit"
              color="primary"
              class="mr-4 px-6"
              height="40px"
              width="100%"
              :loading="form.$busy"
              >{{ buttonLabel }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Building Form
 * ==================================================================================
 **/
import { mapState, mapActions } from 'vuex'
import { isValidMobileNumber } from '@/utils/phone'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import isHexColor from 'validator/lib/isHexColor'
import SelectBuilding from '@/components/fields/SelectBuilding'
import LobbyTvLogoUpload from './LobbyTvLogoUpload'
import LobbyTvBackgroundImageUpload from './LobbyTvBackgroundImageUpload'
import LobbyTvPlaceholderGalleryImageUpload from './LobbyTvPlaceholderGalleryImageUpload'

export default {
  components: {
    LobbyTvLogoUpload,
    LobbyTvBackgroundImageUpload,
    LobbyTvPlaceholderGalleryImageUpload,
    SelectBuilding,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    lobbyTv: {
      type: Object,
      default: () => {
        return null
      },
    },
  },

  data() {
    return {
      form: new Form({
        building_id: null,
        tv_name: '',
        phone_number: '',
        primary_color: '',
        secondary_color: '',
        location_name: '',
        latitude: '',
        longitude: '',
        is_active: true,
        logo: null,
        background_image: null,
        placeholder_gallery_image: null,
      }),
    }
  },

  computed: {
    ...mapState({
      createdBuilding: (state) => state.building.buildingDetails,
    }),

    header() {
      return this.isUpdate ? 'Information' : 'Create TV'
    },

    buttonLabel() {
      return this.isUpdate ? 'Update' : 'Create'
    },

    submitApi() {
      return this.isUpdate ? this.updateLobbyTv : this.createLobbyTv
    },

    isUpdate() {
      return !!this.lobbyTv
    },
  },

  watch: {
    lobbyTv(newValue, oldValue) {
      this.initForm()
    },
  },

  methods: {
    ...mapActions({
      createLobbyTv: 'lobbyTv/createLobbyTv',
      updateLobbyTv: 'lobbyTv/updateLobbyTv',
    }),

    initForm() {
      if (this.lobbyTv) {
        this.form.building_id = this.lobbyTv.building_id
        this.form.tv_name = this.lobbyTv.tv_name
        this.form.phone_number = this.lobbyTv.phone_number
        this.form.primary_color = this.lobbyTv.primary_color.replace('#', '')
        this.form.secondary_color = this.lobbyTv.secondary_color.replace(
          '#',
          ''
        )
        this.form.location_name = this.lobbyTv.location_name
        this.form.latitude = this.lobbyTv.latitude
        this.form.longitude = this.lobbyTv.longitude
        this.form.is_active = this.lobbyTv.is_active
        this.form.logo = this.lobbyTv.logo
        this.form.background_image = this.lobbyTv.background_image
        this.form.placeholder_gallery_image =
          this.lobbyTv.placeholder_gallery_image
        this.lobbyTv.placeholder_gallery_image =
          this.lobbyTv.placeholder_gallery_image
      }
    },

    async submit() {
      if (this.form.$busy || !this.validate()) return

      this.form.$busy = true
      this.form.$clearErrors()
      const forUpdate = this.isUpdate

      await this.submitApi(this.getFormData())
        .then(() => {
          if (forUpdate) {
            this.form.$busy = false
            this.showSnackbar('Lobby TV details successfully updated!')
          } else {
            this.showSnackbar('Lobby TV successfully created!')
            this.$router.push({
              name: 'lobby-tvs',
            })
          }
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    /**
     * Validate form values
     * @return {Boolean}
     */
    validate() {
      this.form.$clearErrors()

      if (!this.form.building_id) {
        this.form.$setError('building_id', 'You have to select a building')
      }

      if (!this.form.tv_name) {
        this.form.$setError('tv_name', 'TV Name is invalid')
      }

      if (!this.form.primary_color) {
        this.form.$setError('primary_color', 'Primary Color is invalid')
      } else {
        if (!isHexColor(this.form.primary_color)) {
          this.form.$setError(
            'primary_color',
            'Primary Color must be a valid hex color code'
          )
        }
      }

      if (!this.form.secondary_color) {
        this.form.$setError('secondary_color', 'Secondary Color is invalid')
      } else {
        if (!isHexColor(this.form.secondary_color)) {
          this.form.$setError(
            'secondary_color',
            'Secondary Color must be a valid hex color code'
          )
        }
      }

      if (!this.form.location_name) {
        this.form.$setError('location_name', 'Location Name is invalid')
      }

      if (!this.form.latitude) {
        this.form.$setError('latitude', 'Latitude is invalid')
      }

      if (!this.form.longitude) {
        this.form.$setError('longitude', 'Longitude is invalid')
      }

      if (!this.form.phone_number) {
        this.form.$setError('phone_number', 'Phone Number is invalid')
      }

      if (
        this.form.phone_number &&
        !isValidMobileNumber(this.form.phone_number)
      ) {
        this.form.$setError(
          'phone_number',
          'Phone number must be valid or in national format'
        )
      }

      return !this.form.$hasErrors()
    },

    getFormData() {
      const form = this.form.$data()
      delete form.logo
      delete form.background_image
      delete form.placeholder_gallery_image

      if (this.isUpdate) {
        form.id = this.lobbyTv.id
        if (form.primary_color && !form.primary_color.includes('#')) {
          form.primary_color = `#${form.primary_color}`
        }
        if (form.secondary_color && !form.secondary_color.includes('#')) {
          form.secondary_color = `#${form.secondary_color}`
        }
        return form
      } else {
        const formData = new FormData()

        Object.keys(form).forEach((key) => {
          if (form[key]) {
            if (
              [
                'logo',
                'background_image',
                'placeholder_gallery_image',
              ].includes(key)
            ) {
              formData.append(key, form[key].file)
            } else {
              let data = form[key]
              if (['primary_color', 'secondary_color'].includes(key)) {
                data = `#${data}`
              }
              formData.append(key, data)
            }
          }
        })
        return formData
      }
    },
  },
}
</script>
<style lang="scss">
.lobby-tv-form {
  &__form {
    max-width: 1000px;
  }

  @media (max-width: 768px) {
    &__form {
      max-width: 100%;
    }
  }
  .hint {
    font-size: 12px; /* Adjust the font size as needed */
    color: #888; /* Choose a color for the hint text */
  }

  .color-preview-wrapper {
    gap: 0.25rem;
    margin-bottom: 0.25rem;

    .preview-box {
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 0.75rem;
      border: 1px solid #000000;
      margin-top: 0.25rem;
    }
  }
}
</style>
