<template>
  <div class="lobby-tv-banner-upload">
    <FileUpload
      :image="form.logo"
      :aspect-ratio="1.89"
      :initial-aspect-ratio="1.89"
      :banner="true"
      :loading="form.$busy || loading"
      :error="error"
      :disable-delete="true"
      @input="toUpload"
      @delete="toDelete"
    />
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Building: Banner Upload
 * ==================================================================================
 **/

import { mapState, mapActions } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import FileUpload from '@/components/fields/FileUpload'

export default {
  name: 'LobbyTvLogoUpload',
  components: {
    FileUpload,
  },
  mixins: [SnackbarMixin, ErrorHandlerMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    forUpdate: {
      type: Boolean,
      default: false,
    },

    error: {
      type: [String, Array],
      default: null,
    },
  },

  data() {
    return {
      form: new Form({
        logo: null,
      }),
    }
  },

  computed: {
    ...mapState({
      lobbyTv: (state) => state.lobbyTv.lobbyTvDetails,
    }),
  },

  watch: {
    lobbyTv: {
      handler(newValue, oldValue) {
        this.initForm()
      },
      deep: true,
    },
  },

  mounted() {
    this.initForm()
  },

  methods: {
    ...mapActions({
      uploadImage: 'lobbyTv/updateLobbyTvImage',
      deleteImage: 'lobbyTv/deleteLobbyTvImage',
    }),

    initForm() {
      if (this.lobbyTv) {
        this.form.logo = this.lobbyTv.logo
      }
    },

    async toUpload(image) {
      if (!this.forUpdate) {
        this.form.logo = image
      } else {
        if (this.form.$busy) return

        this.form.$busy = true
        await this.uploadImage(this.getUploadFormData(image))
          .then(() => {
            this.showSnackbar('Logo uploaded successfully!')
          })
          .catch((err) => {
            this.showSnackbar(this.getErrorMessage(err), false)
          })
          .finally(() => {
            this.form.$busy = false
          })
      }

      this.$emit('updated')
    },

    async toDelete(image) {
      if (!this.forUpdate) {
        this.form.logo = null
      } else {
        if (this.form.$busy) return

        this.form.$busy = true
        await this.deleteImage(this.getDeleteFormData(image))
          .then(() => {
            this.showSnackbar('Logo deleted successfully!')
          })
          .catch((err) => {
            this.showSnackbar(this.getErrorMessage(err), false)
          })
          .finally(() => {
            this.form.$busy = false
          })
      }
    },

    /**
     * As we use the same endpoint for updating the details & images
     * We need to include all the other detail to avoid losing it
     */
    getUploadFormData(image) {
      let formData = new FormData()
      for (var field in this.lobbyTv) {
        let value = this.lobbyTv[field]

        if (Array.isArray(value) && value.length) {
          for (let i = 0; i < value.length; i++) {
            formData.append(`${field}[]`, value[i])
          }
        } else {
          formData.append(field, value)
        }
      }

      formData.append('logo', image.file)

      /* Remove fields not for update */
      formData.delete('background_image')
      formData.delete('placeholder_gallery_image')
      formData.delete('created_at')
      formData.delete('updated_at')
      formData.delete('deleted_at')

      return formData
    },

    getDeleteFormData(image) {
      return {
        id: image.id,
      }
    },

    getImage() {
      return this.form.logo ? this.form.logo.file : null
    },

    hasData() {
      return !!this.getImage()
    },
  },
}
</script>
<style lang="scss" scoped>
.lobby-tv-banner-upload {
  width: 100%;
}
</style>
